<div class="container text-center px-md-5 p-3">

    <h1 class="pt-4">Projects</h1>
    <img src="@/assets/projects-team-work.png" class="img-fluid">

    <div class="row row-cols-1 row-cols-md-2 g-4">
        <div class="col pb-2">
            <div class="card h-100 bg-dark shadow-lg">
                <div class="card-body">
                    <h5 class="card-title h3">Games</h5>
                    <p class="card-text">Working on developing games in different ways and playing with logic.</p>
                    <router-link to="/games" class="btn btn-success">Lets GO!</router-link>
                </div>
            </div>
        </div>
        <div class="col pb-2">
            <div class="card h-100 bg-dark shadow-lg">
                <div class="card-body">
                    <h5 class="card-title h3">GitHub Projects</h5>
                    <p class="card-text">A lot of projects and ideas are here. Check it out and let me know what you
                        think!</p>
                    <router-link to="/github-projects" class="btn btn-success">Lets GO!</router-link>
                </div>
            </div>
        </div>
        <div class="col pb-2">
            <div class="card h-100 bg-dark shadow-lg">
                <div class="card-body">
                    <h5 class="card-title h3">Freetime Projects</h5>
                    <p class="card-text">Playing with different projects that crossed my mind.</p>
                    <router-link to="/projects/beginner" class="btn btn-success">Lets GO!</router-link>
                </div>
            </div>
        </div>
        <div class="col pb-2">
            <div class="card h-100 bg-dark shadow-lg">
                <div class="card-body">
                    <h5 class="card-title h3">Web development</h5>
                    <p class="card-text">Building some websites for me or others.</p>
                    <router-link to="/projects/web" class="btn btn-success">Lets GO!</router-link>
                </div>
            </div>
        </div>
    </div>
</div>
