<div class="container text-center p-md-5">
    <h1 class="text-center py-3">Interesting and useful links</h1>
    <div class="row p-3">
        <h2>CSS</h2>
        <a class="text-decoration-none" href="https://www.w3schools.com/css/" target="_blank">CSS w3school</a>
        <a class="text-decoration-none" href="https://www.kevinpowell.co/" target="_blank">Kevin Powell</a>
        <a class="text-decoration-none" href="https://codingheroes.io/" target="_blank">Jonas Schmedtmann</a>
        <a class="text-decoration-none" href="https://www.youtube.com/layoutland" target="_blank">Layout Land</a>
        <a class="text-decoration-none" href="https://getbootstrap.com/" target="_blank">Bootstrap 5</a>
        <hr class="mx-auto my-2 text-success" style="max-width: 50%;">
    </div>

    <div class="row p-3">
        <h2>JavaScript</h2>
        <a class="text-decoration-none" href="https://www.javascript.com/" target="_blank">JavaScript.com</a>
        <a class="text-decoration-none" href="https://developer.mozilla.org/en-US/docs/Web/JavaScript" target="_blank">JavaScript
            MDN</a>
        <a class="text-decoration-none" href="https://javascript.info/" target="_blank">JavaScript.info</a>
        <a class="text-decoration-none" href="https://codingheroes.io/" target="_blank">Jonas Schmedtmann</a>
        <a class="text-decoration-none" href="https://www.freecodecamp.org/" target="_blank">freeCodeCamp.org</a>
        <a class="text-decoration-none" href="https://www.codecademy.com/" target="_blank">CodeCademy.com</a>
        <a class="text-decoration-none" href="https://www.geeksforgeeks.org/" target="_blank">GeeksForGeeks</a>
        <hr class="mx-auto my-2 text-success" style="max-width: 50%;">
    </div>

    <div class="row p-3">
        <h2>Vue.js</h2>
        <a class="text-decoration-none" href="https://vuejs.org/" target="_blank">Vue.js 3</a>
        <a class="text-decoration-none" href="https://vueschool.io/" target="_blank">VueSchool</a>
        <a class="text-decoration-none" href="https://www.vuemastery.com/" target="_blank">VueMastery</a>
        <a class="text-decoration-none" href="https://vueuse.org/" target="_blank">VueUse</a>
        <a class="text-decoration-none" href="https://udemy.com/" target="_blank">Udemy</a>
        <hr class="mx-auto my-2 text-success" style="max-width: 50%;">
    </div>

    <div class="row p-3">
        <h2>Angular</h2>
        <a class="text-decoration-none" href="https://angular.io/" target="_blank">Angular</a>
        <a class="text-decoration-none" href="https://rxjs.dev/" target="_blank">RxJS</a>
        <a class="text-decoration-none" href="https://therichpost.com/category/angular-16/" target="_blank">Angular Blog</a>
        <hr class="mx-auto my-2 text-success" style="max-width: 50%;">
    </div>

    <div class="row p-3">
        <h2>NODE.js</h2>
        <a class="text-decoration-none" href="https://nodejs.org/en/" target="_blank">NODE.js</a>
        <a class="text-decoration-none" href="https://nodejs.org/en/" target="_blank">Express.js</a>
        <a class="text-decoration-none" href="https://socket.io/" target="_blank">Socket.IO</a>
        <hr class="mx-auto my-2 text-success" style="max-width: 50%;">
    </div>

    <div class="row p-3">
        <h2>Database</h2>
        <a class="text-decoration-none" href="https://www.sqlite.org/index.html" target="_blank">SQLite</a>
        <a class="text-decoration-none" href="https://sequelize.org/" target="_blank">Sequelize</a>
        <a class="text-decoration-none" href="https://www.mongodb.com/" target="_blank">MongoDB</a>
        <hr class="mx-auto my-2 text-success" style="max-width: 50%;">
    </div>

    <div class="row p-3">
        <h2>Python</h2>
        <a class="text-decoration-none" href="https://www.python.org/" target="_blank">Python.org</a>
        <a class="text-decoration-none" href="https://realpython.com/" target="_blank">Real Python</a>
        <a class="text-decoration-none" href="https://flask.palletsprojects.com/en/2.3.x/" target="_blank">Flask</a>
        <a class="text-decoration-none" href="https://pymongo.readthedocs.io/en/stable/" target="_blank">PyMongo</a>
        <hr class="mx-auto my-2 text-success" style="max-width: 50%;">
    </div>

    <div class="row p-3">
        <h2>My mentor</h2>
        <a class="text-decoration-none" href="https://urosjarc.com/" target="_blank">Uroš Jarc</a>
        <hr class="mx-auto my-2 text-success" style="max-width: 50%;">
    </div>

    <div class="row p-3">
        <h2>Other</h2>
        <a class="text-decoration-none" href="https://www.npmjs.com/" target="_blank">npm</a>
        <a class="text-decoration-none" href="https://geekle.us/" target="_blank">geekle</a>
        <a class="text-decoration-none" href="https://openbase.com/" target="_blank">openbase</a>
        <a class="text-decoration-none" href="https://greensock.com/" target="_blank">GSAP</a>
        <a class="text-decoration-none" href="https://udemy.com/" target="_blank">Udemy</a>
        <a class="text-decoration-none" href="https://www.postman.com/" target="_blank">Postman</a>
        <hr class="mx-auto my-2 text-success" style="max-width: 50%;">
    </div>

</div>


