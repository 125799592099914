<div class="container p-sm-4">

    <h1 class="text-center pt-3">My Projects from GitHub</h1>
    <div class=" text-center">
        <img src="@/assets/githubme.png" class="img-fluid">

    </div>

    <div v-show="loading" class="text-center p-5">
        loading...<br>
        <br>
        <app-loader/>
    </div>

    <div v-show="!loading" class="py-3">
        <div class="row row-cols-1 row-cols-md-2 g-4">
                <div class="col" v-for="(repo, index) in repositories" :key="index">
                    <div class="card h-100 shadow bg-dark">
                        <a :href="repo.url" class="text-decoration-none text-white" target="_blank">
                            <div class="card-header fs-3 text-success text-uppercase">
                                <img src="@/assets/github.png" class="float-end" height="40"> {{ repo.name }}
                            </div>

                            <div class="card-body">
                                <p class="card-text">{{ repo.description }}</p>

                                <div class="text-secondary">
                       <span v-for="repobadge in repo.topics" :key="repobadge.id">
                    {{ ' #' + repobadge }}
                        </span>
                                </div>

                            </div>

                            <div class="card-footer">
                                <small class="text-muted">{{ repo.created }}</small>
                            </div>
                        </a>
                    </div>
                </div>
        </div>
    </div>
</div>

<app-goback/>
