<div class="container text-center px-md-5 p-3">

    <h1 class="pt-4">Good Books</h1>
    <img src="@/assets/books.png" class="img-fluid">

    <div class="row row-cols-1 row-cols-md-3 g-4">
        <div class="col pb-2">
            <div class="card border-0 bg-transparent h-100">
                <div class="card-body">
                    <img src="@/assets/cssmodern.jpg" class="shadow img-fluid">
                </div>
            </div>
        </div>
        <div class="col pb-2">
            <div class="card border-0 bg-transparent h-100">
                <div class="card-body">
                    <img src="@/assets/jsgtop.jpg" class="shadow img-fluid">
                </div>
            </div>
        </div>
        <div class="col pb-2">
            <div class="card border-0 bg-transparent h-100">
                <div class="card-body">
                    <img src="@/assets/loopjslogic.jpg" class="shadow img-fluid">
                </div>
            </div>
        </div>
        <div class="col pb-2">
            <div class="card border-0 bg-transparent h-100">
                <div class="card-body">
                    <img src="@/assets/algodatajs.jpg" class="shadow img-fluid">
                </div>
            </div>
        </div>
        <div class="col pb-2">
            <div class="card border-0 bg-transparent h-100">
                <div class="card-body">
                    <img src="@/assets/dtang.jpg" class="shadow img-fluid">
                </div>
            </div>
        </div>
        <div class="col pb-2">
            <div class="card border-0 bg-transparent h-100">
                <div class="card-body">
                    <img src="@/assets/nodejsexpress.jpg" class="shadow img-fluid">
                </div>
            </div>
        </div>
        <div class="col pb-2">
            <div class="card border-0 bg-transparent h-100">
                <div class="card-body">
                    <img src="@/assets/angularlearning.jpg" class="shadow img-fluid">
                </div>
            </div>
        </div>
        <div class="col pb-2">
            <div class="card border-0 bg-transparent h-100">
                <div class="card-body">
                    <img src="@/assets/angularproject.jpg" class="shadow img-fluid">
                </div>
            </div>
        </div>
        <div class="col pb-2">
            <div class="card border-0 bg-transparent h-100">
                <div class="card-body">
                    <img src="@/assets/angularrxjs.jpg" class="shadow img-fluid">
                </div>
            </div>
        </div>
        <div class="col pb-2">
            <div class="card border-0 bg-transparent h-100">
                <div class="card-body">
                    <img src="@/assets/learnpy.jpg" class="shadow img-fluid">
                </div>
            </div>
        </div>
        <div class="col pb-2">
            <div class="card border-0 bg-transparent h-100">
                <div class="card-body">
                    <img src="@/assets/masterpy.jpg" class="shadow img-fluid">
                </div>
            </div>
        </div>
        <div class="col pb-2">
            <div class="card border-0 bg-transparent h-100">
                <div class="card-body">
                    <img src="@/assets/funcpy.jpg" class="shadow img-fluid">
                </div>
            </div>
        </div>
        <div class="col pb-2">
            <div class="card border-0 bg-transparent h-100">
                <div class="card-body">
                    <img src="@/assets/ooppy.jpg" class="shadow img-fluid">
                </div>
            </div>
        </div>
        <div class="col pb-2">
            <div class="card border-0 bg-transparent h-100">
                <div class="card-body">
                    <img src="@/assets/pyexpert.jpg" class="shadow img-fluid">
                </div>
            </div>
        </div>
        <div class="col pb-2">
            <div class="card border-0 bg-transparent h-100">
                <div class="card-body">
                    <img src="@/assets/pyexpert.jpg" class="shadow img-fluid">
                </div>
            </div>
        </div>
        <div class="col pb-2">
            <div class="card border-0 bg-transparent h-100">
                <div class="card-body">
                    <img src="@/assets/javaheadfirst.jpg" class="shadow img-fluid">
                </div>
            </div>
        </div>
    </div>
</div>
