
<div class="p-5 text-center">
    <h1>Page not found or it doesn't exist!</h1>
    <p>Feel free to contact me at any time for any issues!</p>
    <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
        <router-link type="button" class="btn btn-success btn-lg px-4" to="/">Go back home
        </router-link>
        <router-link type="button" class="btn btn-outline-secondary btn-lg px-4" to="/contact">Contact me
        </router-link>
    </div>
</div>


