<div class="container p-md-5 p-3">

    <!-- Dodajanje značke na vrhu, za lepši pregled -->
    <span
            class="badge bg-success m-2"
            v-for="(todo,index) in todos"
    >
            {{ index + 1 }}. {{ todo.naslov }}
        <button @click="izbrisiTodo(index)" type="button" class="btn-close"></button>
    </span>

    <div class="p-3 text-center"><span class="fs-1" data-v-step="korak-zacetek">Todo</span></div>
    <div class="input-group border-0">

        <!-- Vnos naslova in teksta -->
        <input
                v-model.trim="naslovInput"
                type="text"
                placeholder="ToDo name"
                class="form-control"
                maxlength="30"
        >
        <span class="input-group-text">{{ steviloNaslova }}/30</span>
        <input
                v-model.trim="textInput"
                @keyup.enter="dodajTodo"
                type="text"
                placeholder="What is it about?"
                class="form-control"
                maxlength="100"
        >
        <span class="input-group-text">{{ steviloVnosa }}/100</span>
        <button
                @click="dodajTodo"
                class="btn btn-success"
                type="button"
                id="button-addon2"
        >
            Add ToDo
        </button>

    </div>
    <hr>

    <!-- z spustom -->

    <div class="d-flex justify-content-center">
        <div class="p-2 bd-highlight">

            <div class="input-group mb-3">


                <span class="input-group-text bg-warning border-warning">Priority:</span>

                <select class="form-select" aria-label="Default select example" v-model="prioriteta">
                    <option value="">Normal priority</option>
                    <option value="Nizka">Low priority</option>
                    <option value="Visoka">High priority</option>
                </select>
            </div>

        </div>
        <div class="p-2 bd-highlight">
            <div class="input-group mb-3">
                <span class="input-group-text bg-danger border-danger text-white">Finish until:</span>
                <input
                        v-model.lazy="datumKonec"
                        type="date"
                        class="form-control"
                        aria-label="datum"
                        aria-describedby="basic-addon1">
            </div>
        </div>
    </div>
    <!-- Prikaz sporočila: "Vnesite vsa polja" -->
    <transition>
        <p v-if="prikazano" class="bg-danger p-2 m-2 rounded-1 text-center">{{ this.opozorilo }}</p>
    </transition>
    <hr>

    <div class="fs-4 p-3">You added following todos:</div>
    <table class="table bg-white table-striped table-hover">
        <thead>
        <tr>
            <th scope="col">ToDo list</th>
            <th scope="col" style="width: 40px" class="text-center">Priority</th>
            <th scope="col" style="width: 120px" class="text-center">Add date</th>
            <th scope="col" style="width: 120px" class="text-center">Finish until</th>
            <th scope="col" style="width: 40px" class="text-center">Options</th>
        </tr>
        </thead>
        <tbody v-for="(todo,index) in todos" :key="index">
        <tr>
            <td><span class="fw-bold">{{ todo.id }}. {{ todo.naslov.toUpperCase() }}</span><br>
                {{ todo.text }}
            </td>
            <td class="text-center text-warning fw-bold">{{ todo.prioriteta }}</td>
            <td class="text-center text-success fw-bold">{{ todo.datum }}</td>
            <td class="text-center text-danger fw-bold">{{ todo.datumKonca }}</td>
            <td class="text-center">
                <span @click="urediTodo(index)" class="cursor"><img src="https://img.icons8.com/pastel-glyph/20/000000/edit--v1.png"/></span>&nbsp;&nbsp;
                <span @click="izbrisiTodo(index)" class="cursor"><img src="https://img.icons8.com/plasticine/20/000000/filled-trash.png"/></span>
            </td>
        </tr>
        </tbody>
    </table>

    <!-- Izbriši vse To-Do vnose -->
    <div class="d-grid gap-2">
        <button class="btn btn-danger" @click="izbrisVse">
            Delete entire ToDo!
        </button>
    </div>
</div>

<app-goback />

