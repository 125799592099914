<div class="container text-center p-md-5 p-3">
    <h1 class="text-white p-3" v-if="!expired">Countdown until New Year 2023</h1>
    <h1 class="text-danger" v-else>Happy New Year to all!</h1>
    <p class="p-3 fw-bold text-white">days : hours : minutes : seconds</p>
    <div v-if="loaded" class="fs-2 fw-bold">

      <span class="p-3 bg-danger text-white border border-danger rounded shadow">
        {{ prikaziDneve }}
      </span>
        <span class="text-white mx-2">:</span>
        <span class="p-3 bg-danger text-white border border-danger rounded shadow">
        {{ prikaziUre }}
      </span>
        <span class="text-white mx-2">:</span>
        <span class="p-3 bg-danger text-white border border-danger rounded shadow">
        {{ prikaziMinute }}
      </span>
        <span class="text-white mx-2">:</span>
        <span class="p-3 bg-danger text-white border border-danger rounded shadow">
        {{ prikaziSekunde }}
      </span>
    </div>
</div>

<app-goback />
