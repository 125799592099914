<div class="container text-center p-4">
    <h1>Games</h1>
    <img src="@/assets/gamesme.png" class="img-fluid">

    <hr class="my-3" style="margin: 0 auto; width: 50%">
    <h2 class="p-0 m-0">TicTacToe</h2>
    <router-link to="/games/tic-tac-toe"><img src="@/assets/tictactoe.png" alt="" width="300"></router-link>
    <hr class="my-3" style="margin: 0 auto; width: 50%">
</div>

<app-goback />

<!--Hello world-->
