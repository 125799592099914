<div class="container p-md-5">
    <div class="row p-3 d-flex align-items-center justify-content-center">
        <div class="col-lg-3"></div>

        <div class="col-lg-6 p-3">
            <h1 class="text-center">Contact me</h1>

            <p class="text-center lead">Feel free to contact me at any time!</p>
            <form action="https://formspree.io/f/xbjwlrzv"
                  method="POST">

                <div class="form-floating mb-3 bg-transparent">
                    <input type="email" name="email" class="form-control bg-transparent text-white"
                           placeholder="name@example.com">
                    <label>Email address</label>
                </div>

                <div class="form-floating mb-3 bg-transparent">
                    <input type="text" name="name" class="form-control bg-transparent text-white"
                           placeholder="name@example.com">
                    <label>Name</label>
                </div>

                <div class="form-floating">
                <textarea class="form-control bg-transparent text-white" name="message"
                          placeholder="Leave a comment here"
                          style="height: 100px"></textarea>
                    <label>Message</label>
                </div>

                <!-- your other form fields go here -->

                <div class="d-grid gap-2 my-3">
                    <button type="submit" class="btn btn-success btn-lg">Send</button>
                </div>
            </form>
        </div>
        <div class="col-lg-3"></div>
    </div>
</div>


