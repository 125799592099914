<div class="container text-center p-md-5 p-3">

    <h1 class="py-4">Freelance Projects</h1>
    <img src="@/assets/underconstruction.png" class="img-fluid">
    <div class="row row-cols-1 row-cols-md-2 g-4 p-md-4">

        <div class="col" v-for="project in projects" :key="project.id">
            <div class="card text-light bg-dark mb-3 senca">
                <div class="card-header fs-2 text-success border-secondary">
                    {{ project.name }}
                    <p class="card-title text-white">
                        {{ project.what }}
                    </p>
                </div>
                <div class="card-body">
                    <p class="card-text">
                        {{ project.description }}
                    </p>
                    <div class="d-grid gap-2">
                        <router-link class="btn btn-success" type="button" :to="`${project.path}`">See it and try it</router-link>
                        <a class="btn btn-outline-secondary" type="button" :href="`${project.github}`" target="_blank">GitHub</a>
                    </div>
                </div>
                <div class="card-footer fs-6 bg-transparent border-secondary">
                    {{ project.build }}
                </div>
            </div>
        </div>
    </div>

    <app-goback />

</div>
