<div class="container">
    <h1 class="p-2 text-center">Galerry</h1>
    <p class="lead text-center">All the pictures of me and those that i took or made. I love photography using camera or drone.</p>

    <div class="row p-md-3">
        <div class="column">
            <img src="@/assets/media/2.jpg"/>
            <img src="@/assets/media/3.jpg"/>
            <img src="@/assets/media/4.jpg"/>
            <img src="@/assets/media/5.jpg"/>
            <img src="@/assets/media/6.jpg"/>
            <img src="@/assets/media/7.jpg"/>
            <img src="@/assets/media/8.jpg"/>
            <img src="@/assets/media/34.jpg"/>
            <img src="@/assets/media/41.jpg"/>
            <img src="@/assets/media/42.jpg"/>
            <img src="@/assets/media/43.jpg"/>
            <img src="@/assets/media/53.jpg"/>
            <img src="@/assets/media/56.jpg"/>
        </div>
        <div class="column">
            <img src="@/assets/media/9.jpg"/>
            <img src="@/assets/media/10.jpg"/>
            <img src="@/assets/media/11.jpg"/>
            <img src="@/assets/media/12.jpg"/>
            <img src="@/assets/media/13.jpg"/>
            <img src="@/assets/media/14.jpg"/>
            <img src="@/assets/media/27.jpg"/>
            <img src="@/assets/media/28.jpg"/>
            <img src="@/assets/media/29.jpg"/>
            <img src="@/assets/media/35.jpg"/>
            <img src="@/assets/media/45.jpg"/>
            <img src="@/assets/media/46.jpg"/>
            <img src="@/assets/media/48.jpg"/>
            <img src="@/assets/media/54.jpg"/>
            <img src="@/assets/media/57.jpeg"/>
        </div>
        <div class="column">
            <img src="@/assets/media/15.jpg"/>
            <img src="@/assets/media/16.jpg"/>
            <img src="@/assets/media/17.jpg"/>
            <img src="@/assets/media/18.jpg"/>
            <img src="@/assets/media/19.jpg"/>
            <img src="@/assets/media/20.jpg"/>
            <img src="@/assets/media/30.jpg"/>
            <img src="@/assets/media/31.jpg"/>
            <img src="@/assets/media/32.jpg"/>
            <img src="@/assets/media/40.jpg"/>
            <img src="@/assets/media/49.jpg"/>
            <img src="@/assets/media/50.jpg"/>
            <img src="@/assets/media/51.jpg"/>
            <img src="@/assets/media/55.jpg"/>

        </div>
        <div class="column">
            <img src="@/assets/media/21.jpg"/>
            <img src="@/assets/media/22.jpg"/>
            <img src="@/assets/media/23.jpg"/>
            <img src="@/assets/media/24.jpg"/>
            <img src="@/assets/media/25.jpg"/>
            <img src="@/assets/media/26.jpg"/>
            <img src="@/assets/media/33.jpg"/>
            <img src="@/assets/media/36.jpg"/>
            <img src="@/assets/media/37.jpg"/>
            <img src="@/assets/media/38.jpg"/>
            <img src="@/assets/media/52.jpg"/>
            <img src="@/assets/media/58.jpg"/>
        </div>
    </div>

</div>
