<div class="container p-md-5 p-3">

    <h1 class="text-center">Biography</h1>
    <div class="text-center">
        <img src="@/assets/mebaby.png" class="img-fluid">
        <p class="lead">Hello my friend. My name is Danilo and I was born in Slovenia and the Big Bang happened in 1987
            on June.</p>
    </div>

    <diw class="row">
        <div class="col-md-6">
            <p>As a young boy and all the way to my 25th year I was active in a lot of sports like martial arts,
                gymnastics and also I am very active in spiritual world: meditation, yoga, vastu, jyotish,... I was
                hiking a lot and going to a
                lot of places in Slovenia and mostly around the world learning new things and meeting new and
                interesting
                people. When i was in elementary school i was also involved in computers as i was helping others to
                solve problems there.</p>
            <p>My first job was in a coal mine and i was working there as an electrician for almost 10 years. During
                that time i was interested in web development and i made few websites in plain HTML and CSS and some
                JavaScript (copy-past if i'm honest :D). Anyway, it was something that I've learned by myself just by
                doing it and i made few cool websites back then. After some time in a coal mine I started to explore
                human mind more and more and also opened my own office where i was working as a Hypnotherapist with
                International Licence. I was also studying vastu (traditional Indian system of arhitecture) and jyotish
                (traditional Indian system of astrology) for few years at the same time.</p>
        </div>
        <div class="col-md-6 pb-3">
            <img src="@/assets/coalmine.png" class="img-fluid">
        </div>
    </diw>
    <diw class="row">
        <div class="col-md-6">
            <p>As a hypnotherapist and also jyotish and vastu consultant I was active for few years, helping people to
                overcome fears, phobias, helping them to learn better in school or making them better in sport. I tried
                to help as many people as possible. During that time i had few public events and I was helping others to
                understand that field better. I was also very involved in some other fields where I helped people to
                come closer with different sides of spirituality.</p>
            <p>When I started my own company I also made few websites first in plain HTML and CSS and later in
                WordPress. I was quite busy and had to do it like that. But i was always interested in what was behind
                all that WordPress theme and Elementor plugin and others. Anyway, I was quite busy also with building my
                own house at that time as i had 2 kids and with 3d on the way out. Because of being too busy I found new
                job.</p>
        </div>
        <div class="col-md-6 pb-3">
            <img src="@/assets/hipnoterapija.png" class="img-fluid">
        </div>

    </diw>

    <div class="row">
        <div class="col-md-6">
            <p>So I was building websites in WordPress with Elementor and other plugins but that was boring and it just
                wasn't me. I love art, I like making things from scratch and wrapping my energy around it.
                So i decided to look for a mentor to teach me and I finally found one, <a class="text-decoration-none text-success" href="https://urosjarc.com/">Uroš
                    Jarc</a>. Since then I'm learning new things day after day and i enjoy doing that. Building things
                backend or/and frontend is something i want to do from now one. I know I love doing that and if you
                enjoy with something in your free time I enjoy that all the time.</p>
        </div>
        <div class="col-md-6 text-center">
            <img src="@/assets/programing.png" class="img-fluid" width="300">
        </div>
    </div>

    <div class="text-center">
        <h1 class="my-3">My WorkSpace</h1>

        <p class="lead">After some time in Software development i ended up making my own working space.</p>

        <img src="@/assets/media/1.jpg" class="img-fluid shadow border" width="700">
    </div>

</div>
